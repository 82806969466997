@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
    box-sizing: border-box;
    outline: none;

    --dark: #48598d;
    --light: #dcdffa;
    --medium1: #a0a9ed;
    --medium2: #8090d6;
    --medium3: #5266b6;
    --medium4: #d8dcff;
    --medium5: #cdd2ff;
    --black: #1b1b1b;
    --gray: #24242a;
    --white: #f8f8ff;
    --side-spacing-desktop: 8vw;

    --orange: #ce5f1a;
    --green: #008f4a;
    --red: #A81E1E;
    --purple: #81488D;
    --blue: #48598D;
}

.purple-backup {
    --dark: #81488D;
    --light: #F6DCFA;
    --medium1: #D180E1;
    --medium2: #C880D6;
    --medium3: #A552B6;
    --black: #3E004B;
    --white: #FEF8FF;
}

html {
    font-size: calc(0.9vw + 0.6vh);
}

body {
    margin: 0;
    width: 100vw;
    overflow-x: hidden !important;
    font-family: "Quicksand", "San Francisco", "Segoe UI", "Helvetica", "Roboto", sans-serif;
    background-color: var(--light);
    font-size: 1rem;
}

a {
    color: var(--dark);
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

.button {
    --webkit-appearance: none !important;  /* fucking safari again */
    border: none !important;
    cursor: pointer;
    font-family: inherit;
    text-decoration: none !important;
    color: var(--white) !important;
    background-color: var(--medium2);
    padding: 1em 1.5em;
    font-size: 1.15em;
    line-height: 100%;
    display: inline-block;
    border-radius: 2em;
    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: var(--medium3);
}

.button-default {
    background-color: var(--dark) !important;
    font-weight: bold;
}

.button-default:hover {
    background-color: var(--medium2) !important;
}

.h-spacer {
    display: inline-block;
}

.v-spacer {
    display: block;
}

.sec {
    padding: 25vh calc(var(--side-spacing-desktop) * 1.5);
    text-align: center;
    font-size: 1.25em;
}

.sec h1 {
    margin: 0 0 0.1em 0;
    padding: 0;
    font-size: 3em;
}

.sec h2 {
    margin: 0 0 3em 0;
    padding: 0;
    font-size: 0.9em;
    font-style: italic;
    font-weight: normal;
}

.sec p {
    text-align: justify;
    text-align-last: left;
    margin: 0 0 1.2em 0;
    padding: 0;
    text-overflow: ellipsis;
    text-wrap: wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    * {
        --side-spacing-desktop: 3vw;
    }

    html {
        font-size: calc(0.5vw + 1.4vh);
    }

    .sec {
        padding: 20vh var(--side-spacing-desktop);
    }

    .sec h1 {
        font-size: 2em;
    }

    .sec h2 {
        font-size: 0.8em;
    }

    .button {
        font-size: 1.05em;
        padding: 1em 1.15em;
    }
}
