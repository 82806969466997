form {
    text-align: left;
}

.disclaimer {
    font-size: 1.1em;
    margin: 2em 0 !important;
}

.submit {
    display: block !important;
    margin: 0 auto;
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    .disclaimer {
        font-size: 1em;
    }
}