.top-bar {
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100vw;
    background-color: var(--dark);
    padding: 2em var(--side-spacing-desktop);
    color: var(--white);

    display: flex;
    flex-direction: row;
}

.top-bar .logo {
    width: 20%;
    text-align: left;
    font-weight: bold;
}

.top-bar .menu {
    width: 60%;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.top-bar .menu li {
    margin: 0;
    padding: 0;
    display: inline-block;
}

.top-bar .menu li a {
    display: inline-block;
    margin: 0 0.7em;
}

.top-bar .menu li:last-of-type, .top-bar .menu li:first-of-type {
    display: none;
}

.top-bar .logo a, .top-bar .menu a {
    color: var(--white);
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
}

.top-bar .logo a:hover, .top-bar .menu a:hover {
    opacity: 0.8;
}

.top-bar .submit {
    font-weight: bold;
    width: 20%;
    text-align: right;
}

.top-bar .submit a {
    background-color: var(--medium2);
    color: var(--white);
    text-decoration: none;
    padding: 0.4em 1em;
    border-radius: 1em;
    transition: background-color 0.2s ease-in-out;
}

.top-bar .submit a:hover {
    background-color: var(--medium3);
}

.menu-button {
    display: none;
}

footer {
    padding: 15vh var(--side-spacing-desktop);
    background-color: var(--gray);
    color: var(--white);
    text-align: center;
    font-size: 1.1em;
    line-height: 130%;
    background-image: url("../../assets/footer.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

footer a {
    text-decoration: underline;
    color: var(--white);
}

footer a:hover {
    text-decoration: none;
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    .top-bar {
        padding: 2em calc(var(--side-spacing-desktop) * 3);
    }

    .top-bar .logo, .top-bar .submit {
        width: 50%;
    }

    .top-bar .submit a {
        display: none !important;
    }

    .menu-button {
        display: inline-block;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .top-bar .menu {
        text-align: left;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100001;
        visibility: hidden;
        transform: translateY(-100vh);
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: var(--dark);
        font-size: 1.2em;
        padding: 1em 0.5em;
        transition: all 0.2s ease-in-out;
    }

    .top-bar .menu-visible {
        visibility: visible;
        transform: translateY(0);
    }

    .top-bar .menu li, .top-bar .menu li:last-of-type, .top-bar .menu li:first-of-type {
        display: block !important;
    }

    .top-bar .menu li a {
        display: block !important;
        width: 80%;
        padding: 1em;
    }

    .close-menu-button {
        position: fixed;
        top: 1.5em;
        right: calc(var(--side-spacing-desktop) * 3);
    }
}