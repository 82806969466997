.home-banner {
    min-height: 100vh;
    padding: 16vh var(--side-spacing-desktop);
    color: var(--white);
    background-color: var(--dark);
    background-image: url("../../assets/mtn.png");
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@keyframes home-banner-entrance {
    from {
        left: 5vw;
        opacity: 0;
    }

    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes home-phone-entrance {
    from {
        top: -2vh;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes home-flash {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.home-banner h1 {
    position: relative;
    display: block;
    width: 75%;
    margin: 3.5em 0 0 0;
    padding: 0;
    font-size: 3.1em;

    animation-name: home-banner-entrance;
    animation-iteration-count: 1;
    animation-delay: 0;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
}

.home-banner .phone {
    position: relative;
    width: 25%;
    padding-bottom: 46%;    /* aspect ratio */

    background-image: url("../../assets/phone2.png");
    background-repeat: no-repeat;
    background-size: contain;

    animation-name: home-phone-entrance;
    animation-iteration-count: 1;
    animation-delay: 0;
    animation-timing-function: ease-in-out;
    animation-duration: 0.4s;
}

.home-flash {
    animation-name: home-flash;
    animation-iteration-count: infinite;
    animation-delay: 0;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    font-weight: 200;
    user-select: none !important;
}

.home-description {
    padding: 31vh var(--side-spacing-desktop);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.home-description .type {
    position: relative;
    width: 30%;

    background-image: url("../../assets/typing.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
}

.home-description h1 {
    position: relative;
    display: block;
    width: 70%;
    margin: 0;
    padding: 0;
    font-size: 3.3em;
    text-align: justify;
    text-align-last: left;
}

.home-works {
    padding: 20vh calc(var(--side-spacing-desktop) * 3.5) 20vh var(--side-spacing-desktop);
    background-color: var(--black);
    color: var(--white);
}

.home-works h1 {
    margin: 0 0 0.5em 0;
    padding: 0;
    font-size: 4em;
}

.home-works h2 {
    margin: 0 0 1.5em 0;
    padding: 0;
    font-size: 2em;
    text-align: justify;
    text-align-last: left;
}

.home-works a {
    color: var(--light);
    text-decoration: underline;
}

.home-works a:hover {
    text-decoration: none;
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    .home-banner {
        padding: 8vh calc(var(--side-spacing-desktop) * 3);
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .home-banner h1 {
        width: 100%;
        margin: 1.5em 0 1em 0;
    }

    .home-banner .phone {
        width: 60%;
        margin: 0 auto;
        padding-bottom: 111%;    /* aspect ratio */
        background-position: center center;
    }

    .home-description {
        padding: 20vh calc(var(--side-spacing-desktop) * 2);
        flex-direction: column;
    }

    .home-description h1 {
        width: 100%;
        font-size: 2.3em;
    }

    .home-description .type {
        width: 35%;
        padding-bottom: 29.4%;      /* aspect ratio */
        display: block;
        margin: 0 auto 4em auto;
    }

    .home-works {
        padding: 18vh var(--side-spacing-desktop);
    }

    .home-works h1 {
        font-size: 3em;
    }

    .home-works h2 {
        font-size: 1.9em;
    }
}