.message-preview {
    width: 60vw;
    height: 25vw;
    padding: 0 !important;
}

.message-preview textarea {
    resize: none;
    border: none;
    background: none transparent;
    --webkit-appearance: none;
    font-family: inherit;
    font-size: 1.4em;
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}

.message-preview textarea::placeholder {
    color: inherit;
    opacity: 0.5;
}

.message-sender-input {
    display: block;
    width: 60vw;
    margin: 0 auto 0.5em auto;
    border: none;
    background: none transparent;
    --webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
}

.color-picker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50%;
    margin: 0 auto;
}

.color-picker div {
    width: calc(16.6% - 0.6vw);
    padding-bottom: calc(16.6% - 0.6vw - 0.2em);
    margin: 0.3vw;
    border: 0.1em solid var(--black);
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border-radius: 0.6em;
}

.color-picker div:hover {
    opacity: 0.7;
}

.color-blue {
    background-color: var(--blue);
}

.color-orange {
    background-color: var(--orange);
}

.color-red {
    background-color: var(--red);
}

.color-purple {
    background-color: var(--purple);
}

.color-green {
    background-color: var(--green);
}

.color-black {
    background-color: var(--black);
}


/* mobile styles */

@media screen and (max-width: 1023px) {
    .message-preview {
        width: 90vw;
        height: 28vh;
        font-size: 1.1em;
    }

    .message-preview textarea {
        font-size: 1.1em;
    }

    .message-sender-input {
        width: 90vw;
        font-size: 1.1em;
    }

    .color-picker {
        width: 80%;
        margin: 3em auto 0 auto;
    }
}