.submissions-container {
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.submissions-container a {
    text-decoration: none !important;
    color: inherit !important;
}

.submissions-container > div {
    width: calc(33% - 1vw);         /* 3 per row */
    margin: 1.5vh 0.5vw;
    opacity: 0.87;
    transition: opacity 0.2s ease-in-out;
}

.submissions-container > div:hover {
    opacity: 1;
}

.search {
    display: block;
    width: 60%;
    margin: 1em auto;
    padding: 0.4em 1em;
    border: 1px solid var(--dark);
    border-radius: 1em;
    background: none var(--medium4);
    --webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    transition: background 0.2s ease-in-out;
}

.search:focus {
    background-color: var(--medium5);
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    .submissions-container > div {
        width: calc(50% - 1vw);     /* 2 per row */
    }

    .search {
        width: 100%;
    }
}

@media screen and (max-width: 359px) {  /* for taller screens like the galaxy fold */
    .submissions-container {
        flex-direction: column;
        margin: 1em 4vw;
    }

    .submissions-container > div {
        width: calc(100% - 1vw);
    }

    .search {
        width: calc(100% - 8vw);
    }
}