.message {
    position: relative;
    display: block;

    border-radius: 1em;
    
    width: 100%;
    padding-bottom: 90%;

    margin: 0 auto 8.26% auto;  /* aspect ratio */
    font-size: 1.15em;
}

.message-details {
    padding-bottom: 40% !important;
    font-size: 1.4em;
}

.message-sender {
    text-align: left;
    margin: 0 auto 0.3em auto;
    font-size: smaller;
}

.message-sender-details {
    font-size: inherit !important;
    margin: 0 auto 0.5em auto !important;
}

.message-text {
    display: block;
    text-align: center !important;
    text-align-last: center !important;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-overflow: ellipsis;
    text-wrap: wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

p.message-text {    /* only for the actual text and not the textarea, thus emphasizing the p tag */
    padding: 0.5em;
}

/* 184x152 */

.message::after {
    content: " ";
    display: inline-block;
    position: absolute;
    left: 10%;
    top: calc(100%);
    width: 10%;
    padding-bottom: 8.26%;  /* aspect ratio */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.message-blue {
    background-color: var(--blue);
    color: var(--white);
}

.message-blue::after {
    background-image: url("../assets/blue-arrow.png");
}

.message-green {
    background-color: var(--green);
    color: var(--white);
}

.message-green::after {
    background-image: url("../assets/green-arrow.png");
}

.message-red {
    background-color: var(--red);
    color: var(--white);
}

.message-red::after {
    background-image: url("../assets/red-arrow.png");
}

.message-purple {
    background-color: var(--purple);
    color: var(--white);
}

.message-purple::after {
    background-image: url("../assets/purple-arrow.png");
}

.message-black {
    background-color: var(--gray);
    color: var(--white);
}

.message-black::after {
    background-image: url("../assets/black-arrow.png");
}

.message-orange {
    background-color: var(--orange);
    color: var(--white);
}

.message-orange::after {
    background-image: url("../assets/orange-arrow.png");
}

/* mobile styles */

@media screen and (max-width: 1023px) {
    .message {
        font-size: 1em;
    }

    .message-details {
        width: 90vw;
        height: 28vh;
        font-size: 1.1em;
    }

    .message-details p {
        font-size: 1.1em;
    }

    .message-sender-details {
        width: 90vw;
        font-size: 1.1em;
    }
}
